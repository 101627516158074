.file-upload {
  position: relative;
  display: inline-block;
}

.file-upload__label {
  display: block;
  padding: 0.5em 0.7em;
  color: #fff !important;
  background: rgb(246, 168, 33);
  border-radius: 0.4em;
  transition: background 0.3s;

  &:hover {
    cursor: pointer;
    background: #000;
  }
}

.file-upload__input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 1;
  width: 0;
  height: 100%;
  opacity: 0;
}
