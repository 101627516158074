.background-transparent {
  background: transparent !important;
}

@mixin ele-font($name) {
  font-family: $name;
}

@for $i from 0 through 100 {
  .border-radius-#{$i} {
    border-radius: $i * 1px !important;
  }

  .fs-#{$i} {
    font-size: $i * 1px !important;
    &.light-text {
      @include ele-font("montserrat-light");
    }
    &.regular-text {
      @include ele-font("montserrat-regular");
    }
    &.medium-text {
      @include ele-font("montserrat-medium");
    }
    &.demi-bold-text {
      @include ele-font("montserrat-semi-bold");
    }
    &.bold-text {
      @include ele-font("montserrat-bold");
    }
  }

  .w-#{$i} {
    width: $i * 1% !important;
  }

  .pa-#{$i} {
    padding: $i * 1px !important;
  }

  .pr-#{$i} {
    padding-right: $i * 1px !important;
  }

  .pl-#{$i} {
    padding-left: $i * 1px !important;
  }

  // .pt-#{$i} {
  //   padding-top: $i * 1px !important;
  // }

  .pb-#{$i} {
    padding-bottom: $i * 1px !important;
  }

  .plr-#{$i} {
    padding-left: $i * 1px !important;
    padding-right: $i * 1px !important;
  }
  .prl-#{$i} {
    padding-top: $i * 1px !important;
    padding-bottom: $i * 1px !important;
  }
  .ptb-#{$i} {
    padding-top: $i * 1px !important;
    padding-bottom: $i * 1px !important;
  }
  .pbt-#{$i} {
    padding-top: $i * 1px !important;
    padding-bottom: $i * 1px !important;
  }

  .ma-#{$i} {
    margin: $i * 1px !important;
  }

  .mr-#{$i} {
    margin-right: $i * 1px !important;
  }

  .mt-#{$i} {
    margin-top: $i * 1px !important;
  }

  .mb-#{$i} {
    margin-bottom: $i * 1px !important;
  }

  .ml-#{$i} {
    margin-left: $i * 1px !important;
  }

  .mlr-#{$i} {
    margin-left: $i * 1px !important;
    margin-right: $i * 1px !important;
  }
  .mrl-#{$i} {
    margin-left: $i * 1px !important;
    margin-right: $i * 1px !important;
  }
  .mtb-#{$i} {
    margin-top: $i * 1px !important;
    margin-bottom: $i * 1px !important;
  }
  .mbt-#{$i} {
    margin-top: $i * 1px !important;
    margin-bottom: $i * 1px !important;
  }
}

.mlr-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.text-center {
  text-align: center !important;
}

.cursor-pointer {
  cursor: pointer;
}

.pos-relative {
  position: relative;
}

.headline {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: normal;
}

.pull-right {
  float: right;
}

.border-none {
  border: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #ced4da;
}

.vert-middle {
  vertical-align: middle;
}

.flex {
  display: flex;
  &.center {
    align-items: center;
  }
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-around {
  display: flex;
  justify-content: space-around;
}

.flex-1 {
  flex: 1;
}

.fill-width {
  width: 100%;
}

.flex-vertical {
  display: flex;
  align-items: center;
}

.border-bottom {
  border-top: 1px solid #e9ecef;
}
